import { H, HighlightSegmentMiddleware } from 'highlight.run';
import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import App from './App';
import * as serviceWorker from './serviceWorker';

// On chunk load failures, forcibly reload the page. This should be pretty
// much transparent to the user, because chunks are only loaded on navigation
// events, so it just seems like a "weird" navigation event.
window.addEventListener('error', e => {
  if (
    /Loading chunk [\d]+ failed/.test(e.message) ||
    /Importing a module script failed/.test(e.message) ||
    /Failed to fetch dynamically imported module/.test(e.message) ||
    /not a valid JavaScript MIME type/.test(e.message)
  ) {
    window.location.reload();
  }
});

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID, {
  environment:
    process.env.REACT_APP_ENVIRONMENT || process.env.DOPPLER_ENVIRONMENT,
  version: process.env.VERCEL_GIT_COMMIT_SHA,
  enableSegmentIntegration: true,
  enableStrictPrivacy: false,
  enableCanvasRecording: false,
  samplingStrategy: { canvas: 1 },
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment:
    process.env.REACT_APP_ENVIRONMENT || process.env.DOPPLER_ENVIRONMENT,
  release: process.env.VERCEL_GIT_COMMIT_SHA,
  beforeBreadcrumb(breadcrumb, hint) {
    if (
      ['xhr', 'fetch'].includes(breadcrumb.category) &&
      (breadcrumb.data?.url.contains('pub.highlight.run') ||
        breadcrumb.data?.url.contains('rs.fullstory.com') ||
        breadcrumb.data?.url.contains('api.segment.io'))
    ) {
      return null;
    }
    return breadcrumb;
  },
});

H.getSessionURL().then(sessionUrl => {
  Sentry.setContext('highlight', {
    url: sessionUrl,
  });
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
